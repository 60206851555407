import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [
	{ path: '/', redirect: '/home' },
	{
		path: "/home",
		name: "home",
		meta: {
			title: '卡宝卡'
		},
		component: () => import("@/views/homePage.vue"),
	},
	{
		path: "/news_list",
		name: "news",
		meta: {
			title: '新闻公告'
		},
		component: () => import("@/views/newsPage.vue"),
	},
	{
		path: "/news_content",
		name: "newspage",
		meta: {
			title: 'News_content'
		},
		component: () => import("@/views/newscontentPage.vue"),
	},
	{
		path: "/contact",
		name: "contact",
		meta: {
			title: '合作'
		},
		component: () => import("@/views/contactPage.vue"),
	},
	{
		path: "/card",
		name: "card",
		meta: {
			title: '销卡'
		},
		component: () => import("@/views/cardPage.vue"),
	},
	{
		path: "/card_type",
		name: "card_type",
		meta: {
			title: '更多卡种'
		},
		component: () => import("@/views/cardtypePage.vue"),
	},
	{
		path: "/login",
		name: "login",
		meta: {
			title: 'Login'
		},
		component: () => import("@/views/loginPage.vue"),
	},
	{
		path: "/api",
		name: "api",
		meta: {
			title: 'Api'
		},
		component: () => import("@/views/apiPage.vue"),
	},
	{
		path: "/xy_page",
		name: "xy_page",
		meta: {
			title: '卡宝卡礼品卡转让协议'
		},
		component: () => import("@/views/xieyiPage.vue"),
	},
	{
		path: "/sm_page",
		name: "sm_page",
		meta: {
			title: '礼品卡回收说明'
		},
		component: () => import("@/views/shuomingPage.vue"),
	}
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => { 
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
  console.log(1)
});

export default router;
