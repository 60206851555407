import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//引入bootstrap
import 'bootstrap/dist/css/bootstrap.css'
//引入wow
import "animate.css"
import "@/assets/style/common.css";
//使用swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// 全局挂载
Vue.use(VueAwesomeSwiper)
import qs from 'qs'
import axios from 'axios'
Vue.prototype.$axios=axios
Vue.prototype.$qs = qs

Vue.prototype.$baseurl = 'http://shop.hzszl.com/'


new Vue({
  router,
  qs,
  axios,
  render: (h) => h(App),
}).$mount("#app");